
export default function Footer () {
    return ( 
        <div className="ui segment" style={{marginLeft: "0px", marginRight: "0px", marginTop: "25px", padding: "1.5em",textAlign: "center"}}>
            <div>
                <p>
                    <span className="ui small text">
                        © Yasmin Mostoller 2024
                    </span>
                </p>

                </div>
        </div>
)
}